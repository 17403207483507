@font-face {
  font-family: "ABC Diatype";
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url("/fonts/ABCDiatype/ABCDiatype-Light.woff2") format("woff2"),
    url("/fonts/ABCDiatype/ABCDiatype-Light.woff") format("woff");
}

@font-face {
  font-family: "ABC Diatype";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("/fonts/ABCDiatype/ABCDiatype-RegularItalic.woff2") format("woff2"),
    url("/fonts/ABCDiatype/ABCDiatype-RegularItalic.woff") format("woff");
}
@font-face {
  font-family: "ABC Diatype";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("/fonts/ABCDiatype/ABCDiatype-Bold.woff2") format("woff2"),
    url("/fonts/ABCDiatype/ABCDiatype-Bold.woff") format("woff");
}

@font-face {
  font-family: "ABC Diatype";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("/fonts/ABCDiatype/ABCDiatype-Regular.woff2") format("woff2"),
    url("/fonts/ABCDiatype/ABCDiatype-Regular.woff") format("woff");
}

@font-face {
  font-family: "ABC Diatype";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("/fonts/ABCDiatype/ABCDiatype-BoldItalic.woff2") format("woff2"),
    url("/fonts/ABCDiatype/ABCDiatype-BoldItalic.woff") format("woff");
}

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a,
button,
input,
select,
video {
  &:focus-within {
    outline-width: 3px;
    outline-color: transparent ;
    outline-offset: 3px;
    outline-style: solid;
  }
}

.tabbed a,
.tabbed button,
.tabbed input,
.tabbed select {
  &:focus-within {
    outline-width: 3px;
    outline-color: var(--ikon-focus-color, #00f);
    outline-offset: 3px;
    outline-style: solid;
  }
}

body {
  height: 100%;
  /* mobile viewport bug fix */

  overflow-x: hidden;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "ABC Diatype", Arial, Helvetica, sans-serif;
  color: #000;
}

#__next {
  height: 100%;
}

a {
  color: inherit;
  text-decoration-color: rgba(0, 0, 0, 1);
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 1px;
}

p {
  margin-top: 0;
  margin-bottom: 0.6em;
}

.flex-content {
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.html {
    img {
      width: 100% !important;
      height: auto !important;
    }

    iframe {
      width: 100%;
    }

    a {
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-color: #000;
      text-underline-offset: 2px;
      text-decoration-thickness: 0.5px;
      transition: color 0.3s, text-decoration-color 0.3s;
    }
  }

  @media (any-pointer: fine) {
    &.html {
      a {
        &:hover {
          color: #555;
          text-decoration-color: #555;
        }
      }
    }
  }
}
